import { useLocalized } from "../../common/localization/localize";
import { GALLERY_NAME } from "../../entrypoints/galery";

export const getGalleryImageData = (gallery: GALLERY_NAME, index: number) => ({
  imageUrl: `/wallpaintings/gallery/${gallery}/${index}.jpg`,
  alt: `${useLocalized(`wallpaintings_${gallery}_title`)} - ${useLocalized(
    "wallpaintings_image"
  )} ${index + 1}`,
  linkUrl: `/${useLocalized("locale_url_part")}/${useLocalized(
    "gallery_url_part"
  )}/${gallery.toString().replace("-", "_")}.html#${index + 1}`,
  label: useLocalized(`wallpaintings_gallery_labels_${gallery}`)[index]
});
