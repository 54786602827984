import * as React from "react";
import { FC, ReactNode } from "react";
import classes from "./mini-gallery-group.module.css";

export interface IMiniGalleryGroup {
  children: ReactNode;
}

export const MiniGalleryGroup: FC = ({ children }) => (
  <div className={classes.container}>{children}</div>
);
