import * as React from "react";
import { FC } from "react";
import { useLocalized } from "../../../common/localization/localize";
import { GALLERY_NAME } from "../../../entrypoints/galery";
import { MiniGallery } from "../mini-gallery";
import { MiniGalleryGroup } from "../mini-gallery-group";
import { MiniGalleryImage } from "../mini-gallery-image";
import { getGalleryImageData } from "../mini-gallery-image.helper";
import { MiniGalleryText } from "../mini-gallery-text";

export const MiniGalleryMikolow: FC = () => (
  <MiniGallery title={useLocalized("wallpaintings_mikolow_title")}>
    <MiniGalleryGroup>
      <MiniGalleryImage {...getGalleryImageData(GALLERY_NAME.mikolow, 0)} />
      <MiniGalleryImage {...getGalleryImageData(GALLERY_NAME.mikolow, 1)} />
    </MiniGalleryGroup>
    <MiniGalleryText>
      <em>{useLocalized("wallpaintings_mikolow_text")}</em>
    </MiniGalleryText>
  </MiniGallery>
);
