import * as React from "react";
import { FC } from "react";
import { useLocalized } from "../../../common/localization/localize";
import { GALLERY_NAME } from "../../../entrypoints/galery";
import { MiniGallery } from "../mini-gallery";
import { MiniGalleryGroup } from "../mini-gallery-group";
import { MiniGalleryImage } from "../mini-gallery-image";
import { getGalleryImageData } from "../mini-gallery-image.helper";
import { MiniGalleryText } from "../mini-gallery-text";

export const MiniGalleryTarnow: FC = () => (
  <MiniGallery title={useLocalized("wallpaintings_tarnow_title")}>
    <MiniGalleryGroup>
      <MiniGalleryImage {...getGalleryImageData(GALLERY_NAME.tarnow, 0)} />
    </MiniGalleryGroup>
    <MiniGalleryText>
      <em>{useLocalized("wallpaintings_tarnow_text")}</em>
    </MiniGalleryText>
    <MiniGalleryGroup>
      <MiniGalleryImage {...getGalleryImageData(GALLERY_NAME.tarnow, 1)} />
    </MiniGalleryGroup>
    <MiniGalleryGroup>
      <MiniGalleryImage {...getGalleryImageData(GALLERY_NAME.tarnow, 2)} />
      <MiniGalleryImage {...getGalleryImageData(GALLERY_NAME.tarnow, 3)} />
      <MiniGalleryImage {...getGalleryImageData(GALLERY_NAME.tarnow, 4)} />
      <MiniGalleryImage {...getGalleryImageData(GALLERY_NAME.tarnow, 5)} />
    </MiniGalleryGroup>
  </MiniGallery>
);
