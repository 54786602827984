import * as React from "react";
import { FC, ReactNode } from "react";
import {
  Heading,
  HEADING_CLASS,
  HEADING_LEVEL
} from "../../common/components/typography/heading";
import classes from "./mini-gallery.module.css";

export interface IMiniGallery {
  title: string;
  children: ReactNode;
}

export const MiniGallery: FC<IMiniGallery> = ({ title, children }) => (
  <div className={classes.container}>
    <Heading level={HEADING_LEVEL.H2} theme={HEADING_CLASS.BOX}>
      {title}
    </Heading>
    {children && children instanceof Array ? (
      children.map(child => <div className={classes.item}>{child}</div>)
    ) : (
      <div className={classes.item}>{children}</div>
    )}
  </div>
);
