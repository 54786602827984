import * as React from "react";
import { FC, ReactNode } from "react";
import classes from "./mini-gallery-text.module.css";

export interface IMiniGalleryTextProps {
  children: ReactNode;
}

export const MiniGalleryText: FC<IMiniGalleryTextProps> = ({ children }) => (
  <div className={classes.container}>{children}</div>
);
