import * as React from "react";
import { FC } from "react";
import { WallpaintingsEndpoint } from "../../../entrypoints/wallpaintings";
import { LANG } from "../../../common/types/localization";
import { DefaultLayout } from "../../../components/layout/default";

export default (): FC => (
  <DefaultLayout
    lang={LANG.deCH}
    entrypoint={"wallpaintings"}
    page={"wandmalereien"}
  >
    <WallpaintingsEndpoint lang={LANG.deCH} />
  </DefaultLayout>
);
