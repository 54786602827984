import * as React from "react";
import { FC } from "react";
import { useLocalized } from "../../../common/localization/localize";
import { GALLERY_NAME } from "../../../entrypoints/galery";
import { MiniGallery } from "../mini-gallery";
import { MiniGalleryGroup } from "../mini-gallery-group";
import { MiniGalleryImage } from "../mini-gallery-image";
import { getGalleryImageData } from "../mini-gallery-image.helper";
import { MiniGalleryText } from "../mini-gallery-text";

export const MiniGalleryHazlach: FC = () => (
  <MiniGallery title={useLocalized("wallpaintings_hazlach_title")}>
    <MiniGalleryGroup>
      <MiniGalleryImage {...getGalleryImageData(GALLERY_NAME.hazlach, 0)} />
      <MiniGalleryImage {...getGalleryImageData(GALLERY_NAME.hazlach, 1)} />
      <MiniGalleryImage {...getGalleryImageData(GALLERY_NAME.hazlach, 2)} />
      <MiniGalleryImage {...getGalleryImageData(GALLERY_NAME.hazlach, 3)} />
      <MiniGalleryImage {...getGalleryImageData(GALLERY_NAME.hazlach, 4)} />
      <MiniGalleryImage {...getGalleryImageData(GALLERY_NAME.hazlach, 5)} />
      <MiniGalleryImage {...getGalleryImageData(GALLERY_NAME.hazlach, 6)} />
      <MiniGalleryImage {...getGalleryImageData(GALLERY_NAME.hazlach, 7)} />
      <MiniGalleryImage {...getGalleryImageData(GALLERY_NAME.hazlach, 8)} />
      <MiniGalleryImage {...getGalleryImageData(GALLERY_NAME.hazlach, 9)} />
      <MiniGalleryImage {...getGalleryImageData(GALLERY_NAME.hazlach, 10)} />
      <MiniGalleryImage {...getGalleryImageData(GALLERY_NAME.hazlach, 11)} />
      <MiniGalleryImage {...getGalleryImageData(GALLERY_NAME.hazlach, 12)} />
      <MiniGalleryImage {...getGalleryImageData(GALLERY_NAME.hazlach, 13)} />
      <MiniGalleryImage {...getGalleryImageData(GALLERY_NAME.hazlach, 14)} />
    </MiniGalleryGroup>
    <MiniGalleryText>
      {useLocalized("wallpaintings_disclaimer")}
    </MiniGalleryText>
  </MiniGallery>
);
