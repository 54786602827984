import * as React from "react";
import { FC } from "react";
import { FancyLine } from "../common/components/lines/fancy-line";
import {
  Heading,
  HEADING_CLASS
} from "../common/components/typography/heading";
import { useLocalized } from "../common/localization/localize";
import { ILocalizedPage } from "../common/types/page";
import { MiniGalleryBujakow } from "../components/wallpaintings/wrappers/mini-gallery-bujakow";
import { MiniGalleryChorzow } from "../components/wallpaintings/wrappers/mini-gallery-chorzow";
import { MiniGalleryHazlach } from "../components/wallpaintings/wrappers/mini-gallery-hazlach";
import { MiniGalleryJurkow } from "../components/wallpaintings/wrappers/mini-gallery-jurkow";
import { MiniGalleryMikolow } from "../components/wallpaintings/wrappers/mini-gallery-mikolow";
import { MiniGalleryPanewniki } from "../components/wallpaintings/wrappers/mini-gallery-panewniki";
import { MiniGalleryRybnik } from "../components/wallpaintings/wrappers/mini-gallery-rybnik";
import { MiniGallerySwierklany } from "../components/wallpaintings/wrappers/mini-gallery-swierklany";
import { MiniGalleryTarnow } from "../components/wallpaintings/wrappers/mini-gallery-tarnow";
import { MiniGalleryZabrzeAnna } from "../components/wallpaintings/wrappers/mini-gallery-zabrze-anna";
import { MiniGalleryZabrzeJosef } from "../components/wallpaintings/wrappers/mini-gallery-zabrze-josef";
import { MiniGalleryZabrzeTeatr } from "../components/wallpaintings/wrappers/mini-gallery-zabrze-teatr";

export const WallpaintingsEndpoint: FC<ILocalizedPage> = _props => (
  <>
    <Heading level={1} theme={HEADING_CLASS.MAIN}>
      {useLocalized("wallpaintings_title")}
    </Heading>
    <FancyLine />
    <MiniGalleryChorzow />
    <MiniGalleryTarnow />
    <MiniGallerySwierklany />
    <MiniGalleryZabrzeTeatr />
    <MiniGalleryHazlach />
    <MiniGalleryZabrzeAnna />
    <MiniGalleryRybnik />
    <MiniGalleryJurkow />
    <MiniGalleryPanewniki />
    <MiniGalleryBujakow />
    <MiniGalleryMikolow />
    <MiniGalleryZabrzeJosef />
  </>
);
