import * as React from "react";
import { FC } from "react";
import classes from "./mini-gallery-image.module.css";

export interface IMiniGalleryItem {
  imageUrl: string;
  alt: string;
  linkUrl: string;
  label?: string;
}

export const MiniGalleryImage: FC<IMiniGalleryItem> = ({
  imageUrl,
  alt,
  linkUrl,
  label
}) => (
  <label className={classes.container}>
    <a href={linkUrl}>
      <img src={imageUrl} alt={alt} className={classes.image} />
    </a>
    {label}
  </label>
);
